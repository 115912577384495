<template>

    <div>
        <div class="container mt-4">
            <div class="car-container" v-if="this.showCarModels">
                <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation" v-if="this.tabActiveStatus.passenger">
                        <button class="nav-link active" id="passenger-car-tab" data-bs-toggle="tab" data-bs-target="#passenger-car-tab-pane" type="button" role="tab">
                            {{ this.trans[selectedLang]['PASSENGER CARS'] }}
                        </button>
                    </li>
                    <li class="nav-item" role="presentation" v-if="this.tabActiveStatus.suvs">
                        <button class="nav-link" id="suvs-crossover-tab" data-bs-toggle="tab" data-bs-target="#suvs-crossover-tab-pane" type="button" role="tab">
                            {{ this.trans[selectedLang]['SUVS & CROSSOVERS'] }}
                        </button>
                    </li>
                    <li class="nav-item" role="presentation" v-if="this.tabActiveStatus.sports">
                        <button class="nav-link" id="sports-cars-tab" data-bs-toggle="tab" data-bs-target="#sports-cars-tab-pane" type="button" role="tab">
                            {{ this.trans[selectedLang]['SPORTS CARS'] }}
                        </button>
                    </li>
                    <li class="nav-item" role="presentation" v-if="this.tabActiveStatus.commercial">
                        <button class="nav-link" id="comercial-vehicle-tab" data-bs-toggle="tab" data-bs-target="#comercial-vehicle-tab-pane" type="button" role="tab">
                            {{ this.trans[selectedLang]['COMMERCIAL VEHICLES'] }}
                        </button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="passenger-car-tab-pane" role="tabpanel" aria-labelledby="passenger-car-tab" tabindex="0">
                        <ul class="carList">
                            <li v-for="(car,index) in cars" :key="index">
                                <div v-if="carRates.PASSENGER.includes(index)">
                                    <div v-if="configuredCar[index]" class="car-space">
                                        <div @click="selectCar(index,'PASSENGER')">
                                            <img :src="configuredCar[index].image" class="car-img" alt="" />
                                            <h5 class="heading text-start">{{ this.selectedLang != 'en' ? configuredCar[index].ar_name : configuredCar[index].display }}</h5>
                                            <div class="priceBox">
                                                <label class="text-start">{{ this.trans[selectedLang]['Starting price'] }}*</label>
                                                <div class="priceIn" :class="this.selectedLang == 'en'?'flex':''">

                                                    <span class="text-end">
                                                        {{ Number(filterPrice(car.version)).toLocaleString() }}
                                                    </span>
                                                    <span class="currency-title"> {{ this.trans[selectedLang]['CURRENCY'] }} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane fade" id="suvs-crossover-tab-pane" role="tabpanel" aria-labelledby="suvs-crossover-tab" tabindex="0">
                        <ul class="carList">
                            <li v-for="(car,index) in cars" :key="index">
                                <div v-if="carRates.SUV.includes(index)">
                                    <div v-if="configuredCar[index]" class="car-space">
                                        <div @click="selectCar(index,'SUV')">
                                            <img :src="configuredCar[index].image" class="car-img" alt="" />
                                                <h5 class="heading text-start">{{ this.selectedLang != 'en' ? configuredCar[index].ar_name : configuredCar[index].display }}</h5>
                                                <div class="priceBox">
                                                <label class="text-start">{{ this.trans[selectedLang]['Starting price'] }}*</label>
                                                <div class="priceIn" :class="this.selectedLang == 'en'?'flex':''">
                                                    <span>
                                                        {{ Number(filterPrice(car.version)).toLocaleString() }}
                                                    </span>
                                                    <span class="currency-title"> {{ this.trans[selectedLang]['CURRENCY'] }} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane fade" id="sports-cars-tab-pane" role="tabpanel" aria-labelledby="sports-cars-tab" tabindex="0">
                        <ul class="carList">
                           <li v-for="(car,index) in cars" :key="index">
                                <div v-if="carRates.SPORTS.includes(index)">
                                    <div v-if="configuredCar[index]" class="car-space">
                                        <div @click="selectCar(index,'SPORTS')">
                                            <img :src="configuredCar[index].image" class="car-img" alt="" />
                                            <h5 class="heading text-start">{{ this.selectedLang != 'en' ? configuredCar[index].ar_name : configuredCar[index].display }}</h5>
                                            <div class="priceBox"><label>{{ this.trans[selectedLang]['Starting price'] }}*</label>
                                                <div class="priceIn" :class="this.selectedLang == 'en'?'flex':''">
                                                    <span>
                                                        {{ Number(filterPrice(car.version)).toLocaleString() }}
                                                    </span>
                                                    <span class="currency-title"> {{ this.trans[selectedLang]['CURRENCY'] }} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane fade" id="comercial-vehicle-tab-pane" role="tabpanel" aria-labelledby="comercial-vehicle-tab" tabindex="0">
                        <ul class="carList">
                           <li v-for="(car,index) in cars" :key="index">
                                <div v-if="carRates.COMMERCIAL_VEHICLES.includes(index)">
                                    <div v-if="configuredCar[index]" class="car-space">
                                        <div @click="selectCar(index,'COMMERCIAL_VEHICLES')">
                                            <img :src="configuredCar[index].image" class="car-img" alt="" />
                                            <h5 class="heading">{{ this.selectedLang != 'en' ? configuredCar[index].ar_name : configuredCar[index].display }}</h5>
                                            <div class="priceBox"><label>{{ this.trans[selectedLang]['Starting price'] }}*</label>
                                                <div class="priceIn" :class="this.selectedLang == 'en'?'flex':''">
                                                    <span>
                                                        {{ Number(filterPrice(car.version)).toLocaleString() }}
                                                    </span>
                                                    <span class="currency-title"> {{ this.trans[selectedLang]['CURRENCY'] }}  </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <p class="car-discleamer">
                    * {{ this.trans[selectedLang]['DISCLAIMER'] }}. 
                </p>
            </div>
        </div>

        <div class="car-detail-section" v-if="this.checkCarAvaiable">
            <div class="container">
                <div class="headPart">
                    <div class="row">
                        
                        <div class="col-md-12 text-center" v-if="!showCarModels">
                            <button @click="checkCarModel" class="btn2">
                                {{ this.trans[selectedLang]['SELECT A DIFFERENT MODEL'] }}
                            </button>
                        </div>
                        <div class="col-md-6">
                            <div class="rightDetail">
                                <img :src="this.specificCarGrade.image" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="gradeMinfo">
                                <div class="dropdown">
                                    <button class="dropdownBtnCusomt dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ this.trans[selectedLang]['SELECT A GRADE'] }}
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li v-for="(specificCar,index) in specificCarVersion" :key="index">
                                            <div v-if="specificCar.active != '0' && specificCar.price">
                                                <button class="dropdown-item" type="button" @click="selectCarGrade(index,'1','2')">{{ specificCar.Version_Label }}</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="detail">
                                    <h1 class="car-grade-">{{ this.specificCarGrade.version_label }}</h1>
                                    <div class="custom-selectedValue">
                                        <span class="span">
                                            {{ Number(this.specificCarGrade.price).toLocaleString() }} <i>{{ this.trans[selectedLang]['CURRENCY'] }}</i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="descriptionBox">
                    <div class="row borderBottom">
                        <div class="col-2 col-md-6">
                            <h3 class="main-title">{{ this.trans[selectedLang]['AGE'] }}</h3>
                        </div>
                        <div class="col-10 col-md-6">
                            <div class="form-check form-switch float-end">
                                <label :class="this.checkAgeStatus ? 'form-check-label me-1 text-gray':'form-check-label me-1'">{{ this.trans[selectedLang]['Below 25 years'] }}</label>
                                <input class="form-check-input" type="checkbox"
                                    v-model="checkAgeStatus"
                                    @change="ageChecking()" role="switch" checked
                                >
                                <label :class="this.checkAgeStatus ? 'form-check-label ms-1 ':'form-check-label ms-1 text-gray'">{{ this.trans[selectedLang]['Above 25 years'] }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row borderBottom align-items-normal-sm">
                        <div class="col-7 col-md-6">
                            <h3 class="main-title">{{ this.trans[selectedLang]['Down Payment'] }} {{ this.model.downpaymentRange }}%</h3>
                        </div>
                        <div class="col-5 col-md-6 text-end">
                            <h5 class="pricing-text">
                                {{ Number(parseFloat(this.downpayment.totalAmount).toFixed(0)).toLocaleString() }}
                                <span>{{ this.trans[selectedLang]['CURRENCY'] }}</span>
                            </h5>
                            <div class="rangerSlider float-end">
                                <input type="range" :min="this.downpayment.min" :max="this.downpayment.max" @change="downPaymentRangeSlider()" v-model="model.downpaymentRange" class="form-range"  id="customRange1">
                            </div>
                        </div>
                    </div>
                    <div class="row borderBottom">
                        <div class="col-7 col-md-6">
                            <h3 class="main-title">{{ this.trans[selectedLang]['INSURANCE AMOUNT'] }}</h3>
                        </div>
                        <div class="col-5 col-md-6 text-end">
                            <h5 class="pricing-text">
                                {{ Number(parseFloat(this.insurance.totalAmount).toFixed(0)).toLocaleString() }}
                                <span>{{ this.trans[selectedLang]['CURRENCY'] }}</span>
                            </h5>
                        </div>
                    </div>
                    <div class="row borderBottom">
                        <div class="col-7 col-md-6">
                            <h3 class="main-title">{{ this.trans[selectedLang]['FINANCE AMOUNT'] }}</h3>
                        </div>
                        <div class="col-5 col-md-6 text-end">
                            <h5 class="pricing-text">
                                {{ Number(parseFloat(this.financeAmount).toFixed(0)).toLocaleString() }}
                                <span>{{ this.trans[selectedLang]['CURRENCY'] }}</span>
                            </h5>
                        </div>
                    </div>
                    <div class="row borderBottom">
                        <div class="col-7 col-md-6">
                            <h3 class="main-title">{{ this.trans[selectedLang]['PROFIT RATE'] }}</h3>
                        </div>
                        <div class="col-5 col-md-6 text-end">
                            <h5 class="pricing-text">
                                {{ Number(parseFloat(this.bankProfit).toFixed(0)).toLocaleString() }}
                                <span>{{ this.trans[selectedLang]['CURRENCY'] }}</span>
                            </h5>
                        </div>
                    </div>

                    <div class="row borderBottom border-bottom-none">
                        <div class="col-7 col-md-6">
                            <h3 class="main-title">{{ this.trans[selectedLang]['TENURE'] }}</h3>
                        </div>
                        <div class="col-5 col-md-6 text-end">
                            <h5 class="pricing-text">
                                {{ this.model.tenureRange }} {{ this.trans[selectedLang]['Years'] }}
                            </h5>
                            <div class="rangerSlider float-end">
                                <input type="range" class="form-range" @change="tenureRangeSlider()" v-model="model.tenureRange" min="1" max="5" id="customRange2">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-7 col-md-6">
                            <h4 class="emi-heading">
                                <span class="emi-titles">{{ this.trans[selectedLang]['MONTHLY PAYMENT'] }}*</span>
                            </h4>
                        </div>
                        <div class="col-5 col-md-6 text-end">
                            <h4 class="emi-heading">
                                <span class="emi-prices" :class="this.selectedLang == 'en'?'flex':''">
                                    {{ Number(parseFloat(this.emi).toFixed(0)).toLocaleString() }}
                                    <span> {{ this.trans[selectedLang]['CURRENCY'] }} </span>
                                </span>
                            </h4>
                        </div>
                    </div>

                    <div class="mt-4">

                        <a href="javascript:void(0)" @click="sendFinanceButtonClick" class="btn btn-nissan" >
                            <!-- <span v-if="btnCaption.trim() ==='a'">
                            {{ this.trans[selectedLang]['SEND A FINANCE REQUEST'] }}
                            </span>
                            <span v-else>
                            {{ this.trans[selectedLang]['GET A FINANCE REQUEST'] }}
                            </span> -->
                            <span>
                            {{ this.trans[selectedLang]['GET A FINANCE QUOTE'] }}
                            </span>
                        </a>
                        <p class="disclaimer mt-2">
                            * {{ this.trans[selectedLang]['TERMS AND CONDITION'] }}
                        </p>
                    </div>
                </div>
            </div>
           
        </div>
        
        <div class="mt-5 pt-5"></div>

    </div>

</template>
<style>
    .btn-nissan span{
        color:#fff;
    }
</style>
<script>

import translatedLang from '../../json/translations.json';
import { adobeAnalyticCode } from "../adobeAnalyticsNew.js";
import axios from "axios";

export default {
    name: 'FinanceCalculator',
    props: {
        newcars: Object,
        vehiclerates: Object,
        carConfiguration: Object
    },
    mounted() {

        axios.get(`./read.php`).then((response) => {
        // console.log(response,"zzzz")
            this.btnCaption = response.data;
        });

        // console.log(this.newcars.data.cars)
        // console.log(this.cars)
        this.cars = this.newcars.data.cars;
        this.carRates = this.vehiclerates;
        this.configuredCar = this.carConfiguration.cars_config;

        // Checking cars availability in each category | hide category which has no cars.
        for (const newitem in this.cars) {
            for (const grade in this.cars[newitem].version) {
                if(this.carRates.PASSENGER.includes(newitem) && this.cars[newitem].version[grade].price && this.cars[newitem].version[grade].active != '0'){
                    this.tabActiveStatus.passenger = true;
                }
                if(this.carRates.SUV.includes(newitem) && this.cars[newitem].version[grade].price && this.cars[newitem].version[grade].active != '0'){
                    this.tabActiveStatus.suvs = true;
                }
                if(this.carRates.SPORTS.includes(newitem) && this.cars[newitem].version[grade].price && this.cars[newitem].version[grade].active != '0'){
                    this.tabActiveStatus.sports = true;
                }
                if(this.carRates.COMMERCIAL_VEHICLES.includes(newitem) && this.cars[newitem].version[grade].price && this.cars[newitem].version[grade].active != '0'){
                    this.tabActiveStatus.commercial = true;
                }
            }
        }
        // Checking cars availability in each category | hide category which has no cars.
    },
    created() {
        let uri = window.location.search.substring(1); 
        let params = new URLSearchParams(uri);
        // console.log(params.get("lang") ?? 'en');
        this.selectedLang = params.get("lang") ?? 'en';
        if(this.selectedLang != 'en' && this.selectedLang != 'ar'){
            this.selectedLang = 'en';
        }
    },
    computed: {
        
    },
    watch: {
        'model.downpaymentRange': function() {
            this.downPaymentRangeSlider();
        },
        'model.tenureRange': function() {
            this.tenureRangeSlider();
        }
    },
    data() {
        return {
            btnCaption: "a",
            tabActiveStatus: {
                passenger:false,
                suvs:false,
                sports:false,
                commercial:false
            },
            startingCarPrices: {
                'passenger_price': [],
                'suv_price': [],
                'sport_price': [],
                'commercial_price': [],
            },
            selectedCarCategory: "",
            isNissanPatrolInsuranceRate: false,
            insurance_amount: 0,
            showCarModels: true,
            trans: translatedLang,
            selectedLang: null,
            carRates: {},
            cars: {},
            configuredCar: {},
            checkCarAvaiable: false,
            specificCarVersion: {},
            specificCarGrade: {
                'carCategory': null,
                'model': null,
                'vehicle_id': null,
                'key_index': null,
                'version_label': null,
                'price': 'No Price',
                'image': null,
            },
            model: {
                downpaymentRange: 20,
                tenureRange: 5,
            },
            checkAgeStatus: true,
            insurance: {
                percentage: null,
                totalAmount: null,
            },
            downpayment: {
                min: 20,
                max: null,
                totalAmount: null,
            },
            financeAmount: 0,
            bankProfit: 0,
            emi: 0,
            gradeCheckAdobe: 0
        }
    },
    methods: {
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        checkCarModel(){
            var mobile = this.isMobile()
            if(mobile){
                this.showCarModels = true;
            }
        },
        filterPrice(object) {

            for (const key in object) {
                if (Object.hasOwnProperty.call(object, key)) {
                    const element = object[key];
                    if(element.price && element.active == 1)
                        return element.price;
                }
            }
            return 0;
        },
        sendFinanceButtonClick(){
            
            var allData = {
                'category': this.specificCarGrade.carCategory, // PASSENGER
                'model': this.specificCarGrade.model, // MAXIMA
                'version_name': this.specificCarGrade.version_label, // MAXIMA S
                'vehicleid': this.specificCarGrade.vehicle_id, // A36
                'language': this.selectedLang, // en or ar
                // "button_name": this.btnCaption.trim()==="a"?"SEND A FINANCE REQUEST":"GET A FINANCE QUOTE",
                "button_name": "GET A FINANCE QUOTE",
                'new_downPayment': Number(parseFloat(this.downpayment.totalAmount).toFixed(0)).toLocaleString(),
                'new_insurance': Number(parseFloat(this.insurance.totalAmount).toFixed(0)).toLocaleString(),
                'new_profit': Number(parseFloat(this.bankProfit).toFixed(0)).toLocaleString(),
                'new_year': Number(parseFloat(this.model.tenureRange).toFixed(0)).toLocaleString(),
                'new_monthlyPayment': this.emi,
                'new_selectedCarPrice': Number(parseFloat(this.specificCarGrade.price).toFixed(0)).toLocaleString()
            };
            adobeAnalyticCode(allData);
            // window.location.href = "https://en-ae-du.dark.prod.heliosnissan.net/services/finance-request.html";

            window.open(
                'https://'+this.selectedLang+'.nissan-dubai.com/services/finance-request.html?modelName='+this.specificCarGrade.model
                
                +'&currency='+this.trans[this.selectedLang]['CURRENCY'] // A3
                +'&model='+this.specificCarGrade.vehicle_id // A3
                +'&grade='+this.specificCarGrade.version_label
                +'&dp=' +Math.round(this.downpayment.totalAmount)
                +'&insurance='+ Math.round(this.insurance.totalAmount)
                +'&price='+Math.round(this.specificCarGrade.price)
                +'&rate='+Math.round(this.bankProfit)
                +'&tenure='+this.model.tenureRange
                +'&emi='+Math.round(this.emi)
            
                ,
                '_parent' // <- This is what makes it open in a new window.
              );
        },
        ageChecking(){
            if (this.checkAgeStatus == true) {

                // Age Above 25
                if(this.isNissanPatrolInsuranceRate){

                    this.insurance.percentage = this.carRates.insurance_rate_a25.Nissan_Patrol.rate;
                    // console.log(this.insurance.percentage, "Patrol Above 25")
                   
                }else{

                    if(this.selectedCarCategory == 'PASSENGER'){

                        this.insurance.percentage = this.carRates.insurance_rate_a25.PASSENGER.rate;
                    }else if(this.selectedCarCategory == 'SUV'){

                        this.insurance.percentage = this.carRates.insurance_rate_a25.SUV.rate;
                    }else if(this.selectedCarCategory == 'SPORTS'){

                        this.insurance.percentage = this.carRates.insurance_rate_a25.SPORTS.rate;
                    }else if(this.selectedCarCategory == 'COMMERCIAL_VEHICLES'){

                        this.insurance.percentage = this.carRates.insurance_rate_a25.COMMERCIAL_VEHICLES.rate;
                    }
                    console.log(this.insurance.percentage, "Above 25")
                }
            }else{

                // Age Below 25
                if(this.isNissanPatrolInsuranceRate){

                    this.insurance.percentage = this.carRates.insurance_rate_b25.Nissan_Patrol.rate;
                    // console.log(this.insurance.percentage, "Patrol Below 25")
                }else{

                    if(this.selectedCarCategory == 'PASSENGER'){

                        this.insurance.percentage = this.carRates.insurance_rate_b25.PASSENGER.rate;
                    }else if(this.selectedCarCategory == 'SUV'){

                        this.insurance.percentage = this.carRates.insurance_rate_b25.SUV.rate;
                    }else if(this.selectedCarCategory == 'SPORTS'){

                        this.insurance.percentage = this.carRates.insurance_rate_b25.SPORTS.rate;
                    }else if(this.selectedCarCategory == 'COMMERCIAL_VEHICLES'){

                        this.insurance.percentage = this.carRates.insurance_rate_b25.COMMERCIAL_VEHICLES.rate;
                    }
                    console.log(this.insurance.percentage, "Below 25")
                }
            }

            this.selectCarGrade(this.specificCarGrade.key_index,'0','0');
        },
        insuranceMinimumPremiumAmountCheck(){
            if (this.checkAgeStatus == true) {

                // Age Above 25
                if(this.isNissanPatrolInsuranceRate){

                    // Checking insurance amount is lesser than "minimun premium" 
                    if(this.insurance_amount > this.carRates.insurance_rate_a25.Nissan_Patrol.minimum_premium){
                        this.insurance.totalAmount = this.insurance_amount;
                    }else{
                        this.insurance.totalAmount = this.carRates.insurance_rate_a25.Nissan_Patrol.minimum_premium;
                    }
                }else{

                    if(this.selectedCarCategory == 'PASSENGER'){

                        // Checking insurance amount is lesser than "minimun premium" 
                        if(this.insurance_amount > this.carRates.insurance_rate_a25.PASSENGER.minimum_premium){
                            this.insurance.totalAmount = this.insurance_amount;
                        }else{
                            this.insurance.totalAmount = this.carRates.insurance_rate_a25.PASSENGER.minimum_premium;
                        }
                    }else if(this.selectedCarCategory == 'SUV'){

                        // Checking insurance amount is lesser than "minimun premium" 
                        if(this.insurance_amount > this.carRates.insurance_rate_a25.SUV.minimum_premium){
                            this.insurance.totalAmount = this.insurance_amount;
                        }else{
                            this.insurance.totalAmount = this.carRates.insurance_rate_a25.SUV.minimum_premium;
                        }
                    }else if(this.selectedCarCategory == 'SPORTS'){

                        // Checking insurance amount is lesser than "minimun premium" 
                        if(this.insurance_amount > this.carRates.insurance_rate_a25.SPORTS.minimum_premium){
                            this.insurance.totalAmount = this.insurance_amount;
                        }else{
                            this.insurance.totalAmount = this.carRates.insurance_rate_a25.SPORTS.minimum_premium;
                        }
                    }else if(this.selectedCarCategory == 'COMMERCIAL_VEHICLES'){

                        // Checking insurance amount is lesser than "minimun premium" 
                        if(this.insurance_amount > this.carRates.insurance_rate_a25.COMMERCIAL_VEHICLES.minimum_premium){
                            this.insurance.totalAmount = this.insurance_amount;
                        }else{
                            this.insurance.totalAmount = this.carRates.insurance_rate_a25.COMMERCIAL_VEHICLES.minimum_premium;
                        }
                    }
                }
            }else{

                // Age Below 25
                if(this.isNissanPatrolInsuranceRate){

                    // Checking insurance amount is lesser than "minimun premium" 
                    if(this.insurance_amount > this.carRates.insurance_rate_b25.Nissan_Patrol.minimum_premium){
                        this.insurance.totalAmount = this.insurance_amount;
                    }else{
                        this.insurance.totalAmount = this.carRates.insurance_rate_b25.Nissan_Patrol.minimum_premium;
                    }
                }else{

                    if(this.selectedCarCategory == 'PASSENGER'){

                        // Checking insurance amount is lesser than "minimun premium" 
                        if(this.insurance_amount > this.carRates.insurance_rate_b25.PASSENGER.minimum_premium){
                            this.insurance.totalAmount = this.insurance_amount;
                        }else{
                            this.insurance.totalAmount = this.carRates.insurance_rate_b25.PASSENGER.minimum_premium;
                        }
                    }else if(this.selectedCarCategory == 'SUV'){

                        // Checking insurance amount is lesser than "minimun premium" 
                        if(this.insurance_amount > this.carRates.insurance_rate_b25.SUV.minimum_premium){
                            this.insurance.totalAmount = this.insurance_amount;
                        }else{
                            this.insurance.totalAmount = this.carRates.insurance_rate_b25.SUV.minimum_premium;
                        }
                    }else if(this.selectedCarCategory == 'SPORTS'){

                        // Checking insurance amount is lesser than "minimun premium" 
                        if(this.insurance_amount > this.carRates.insurance_rate_b25.SPORTS.minimum_premium){
                            this.insurance.totalAmount = this.insurance_amount;
                        }else{
                            this.insurance.totalAmount = this.carRates.insurance_rate_b25.SPORTS.minimum_premium;
                        }
                    }else if(this.selectedCarCategory == 'COMMERCIAL_VEHICLES'){

                        // Checking insurance amount is lesser than "minimun premium" 
                        if(this.insurance_amount > this.carRates.insurance_rate_b25.COMMERCIAL_VEHICLES.minimum_premium){
                            this.insurance.totalAmount = this.insurance_amount;
                        }else{
                            this.insurance.totalAmount = this.carRates.insurance_rate_b25.COMMERCIAL_VEHICLES.minimum_premium;
                        }
                    }
                }
            }
        },
        downPaymentRangeSlider(){
            this.downpayment.totalAmount = (parseInt(this.specificCarGrade.price) + parseInt(this.insurance.totalAmount)) * this.model.downpaymentRange / 100;
            this.financeAmountCalculation();
            this.bankProfitCalculation();
            this.tenureRangeSlider();
        },
        selectCar(vehicleId, currentCarCategory) {
            console.log(vehicleId)
            // console.log(currentCarCategory)
            this.selectedCarCategory = currentCarCategory;

            var mobile = this.isMobile()
            if(mobile){
                this.showCarModels = false;
            }
            
            this.specificCarGrade.vehicle_id = vehicleId;
            this.specificCarGrade.model = this.configuredCar[vehicleId].display;

            // Checking for Patrol, to set the insurance rate
            var nissan_patrol = this.specificCarGrade.model.toLowerCase();
            this.isNissanPatrolInsuranceRate = nissan_patrol.includes("patrol");
            // console.log(textdata.includes("patrol"))
            // Checking for Patrol, to set the insurance rate - END

            this.checkCarAvaiable = true;
            const allCars = this.cars;
            var specificCarData = allCars[vehicleId];

            this.specificCarVersion = specificCarData['version'];

            for (const item in specificCarData.version) {
                // console.log(item);
                if(specificCarData.version[item].active != 0){
                    var specificCarGradeId = item;
                    break;
                }
            }
            this.selectCarGrade(specificCarGradeId,'1','0');
            this.ageChecking();
        },
        selectCarGrade(specificCarGradeId,adobeAnaStatus,sendDropdownAdobeGrade){

            console.log(specificCarGradeId)

            this.model.downpaymentRange = this.downpayment.min;
            this.specificCarGrade.key_index = specificCarGradeId;

            const specificCarGradeData = this.specificCarVersion;
            this.specificCarGrade.version_label = specificCarGradeData[specificCarGradeId].Version_Label;
            this.specificCarGrade.price = specificCarGradeData[specificCarGradeId].price;
            this.specificCarGrade.image = specificCarGradeData[specificCarGradeId].image;

            if(adobeAnaStatus == '1'){
                if(sendDropdownAdobeGrade == '2'){
                    this.gradeCheckAdobe = 1;
                }
                this.adobeAnalyticsSendData(this.specificCarGrade.vehicle_id);
            }

            //--- Start - Insurance Calculation
            this.insurance_amount = parseInt(this.specificCarGrade.price * this.insurance.percentage / 100)
            this.insuranceMinimumPremiumAmountCheck();
            // this.insurance.totalAmount = parseInt(this.specificCarGrade.price * this.insurance.percentage / 100)
            //--- End - Insurance Calculation

            //--- Start - DownPayment Calculation
            this.downpayment.totalAmount = (parseInt(this.specificCarGrade.price) + parseInt(this.insurance.totalAmount))  * this.downpayment.min / 100;
            // get max downPayment Percentage = (car_price - 20,000) / car_price * 100;
            this.downpayment.max = parseInt((parseInt(this.specificCarGrade.price) - 20000) / this.specificCarGrade.price * 100);
            //--- End - DownPayment Calculation

            this.financeAmountCalculation();
            this.bankProfitCalculation();
            this.tenureRangeSlider();
        },
        financeAmountCalculation(){
            this.financeAmount = (parseInt(this.specificCarGrade.price) + parseInt(this.insurance.totalAmount)) - parseInt(this.downpayment.totalAmount);
        },
        bankProfitCalculation(){
            this.bankProfit = this.financeAmount * this.carRates.bank_rate / 100 * this.model.tenureRange;
        },
        tenureRangeSlider(){
            this.bankProfitCalculation();
            this.emi = (parseInt(this.bankProfit) + parseInt(this.financeAmount)) / (this.model.tenureRange * 12);
        },
        adobeAnalyticsSendData(){

            var carCategory = "";
            if(this.carRates.PASSENGER.includes(this.specificCarGrade.vehicle_id)){
                carCategory = 'PASSENGER CAR';
            }
            if(this.carRates.SUV.includes(this.specificCarGrade.vehicle_id)){
                carCategory = 'SUVS & CROSSOVERS';
            }
            if(this.carRates.SPORTS.includes(this.specificCarGrade.vehicle_id)){
                carCategory = 'SPORTS CARS';
            }
            if(this.carRates.COMMERCIAL_VEHICLES.includes(this.specificCarGrade.vehicle_id)){
                carCategory = 'COMMERCIAL VEHICLES';
            }

            this.specificCarGrade.carCategory = carCategory;
            var allData = {
                'category': this.specificCarGrade.carCategory,
                'version_name': this.gradeCheckAdobe ? this.specificCarGrade.version_label: null, // MAXIMA SV
                'model': this.specificCarGrade.model.toUpperCase(), // MAXIMA
                'vehicleid': this.specificCarGrade.vehicle_id, //eg: A36
                'language': this.selectedLang,
            };
            adobeAnalyticCode(allData);
        }
    }
}
</script>

